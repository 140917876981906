import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

/**
 * Storage service.
 */
@Injectable({ providedIn: 'root' })
export class StorageService {
	/**
	 * Window.
	 *
	 * @private
	 */
	private window: Window | null;

	/**
	 * Component constructor.
	 *
	 * @param document Document.
	 */
	constructor(@Inject(DOCUMENT) private document: Document) {
		this.window = this.document.defaultView;
	}

	/**
	 * Clean storage.
	 */
	clean(): void {
		this.window?.localStorage.clear();
	}

	/**
	 * Get value from storage.
	 *
	 * @param key Storage key.
	 */
	get(key: string) {
		const value = this.window?.localStorage.getItem(key);
		return value ? JSON.parse(value) : undefined;
	}

	/**
	 * Remove value from storage.
	 *
	 * @param key Storage key.
	 */
	remove(key: string): void {
		this.window?.localStorage.removeItem(key);
	}

	/**
	 * Set value to storage.
	 *
	 * @param key   Storage key.
	 * @param value Storage value.
	 */
	set(key: string, value: any): void {
		this.window?.localStorage.setItem(key, JSON.stringify(value));
	}
}
